import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";

const TermsConditions = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "right",
          button: "account" // cta, account, null
        }}
      >
        <div className="inner-banner pt-29 pb-6">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9" md="11">
                <div className="px-xl-15 px-lg-8 text-center">
                  <h2 className="title gr-text-2 mb-9">
                    Terms &amp; Conditions
                  </h2>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="main-block pb-21">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10" xl="8">
                <div className="px-lg-8 px-xl-3">
                  <div className="single-block mb-11">
                    <p className="gr-text-9">
                      <i>Last updated: September 20, 2022</i>
                      <ol type="A">
                        <li>Pear Commerce has developed and operates a marketing and data platform (the “Platform”) that connects CPG Seller’s advertising to consumers and the
                          stores at which they shop.</li>
                        <li>CPG Seller at its own expense and responsibility, designs advertisements promoting various CPG products (“CPG Products”). The Pear Platform will, on
                          the terms in this Agreement, connect and drive traffic from certain Consumers interested in a particular CPG Product, to the sites and platforms of certain retailers
                          offering the specific CPG Product</li>
                        <li>
                          Retailer is a retail seller CPG Products. Retailer operates a website and digital channel selling CPG products online and accepts traffic through the
                          Platform.
                        </li>
                        <li>
                          Through the Platform, Pear Commerce leverages CPG digital advertising investment to convert consumers (“Consumers”) into web traffic and/or
                          ecommerce accounts and transactions with retailers
                        </li>
                      </ol>
                      The Parties agree as follows:
                      <ol type="1">
                        <li>
                          <u>Joint Responsibilities; Technology Interface.</u> CPG Seller and Pear Commerce shall work together, along with any retailer if applicable, to implement,
                          launch and maintain an interface between their respective technology and information systems including interaction and interface between the Platform and
                          applicable CPG digital advertisement or website.
                        </li>
                        <li>
                          <u>Additional Software.</u> For any software or code provided by Pear Commerce under this Agreement (if any), Pear Commerce grants CPG a non-exclusive,
                          limited, non-transferable, non-sublicense-able, license for the Term of this Agreement to use the software or code, in object-code only, for CPG Seller’s internal
                          business purposes only and only for the purpose of making permitted use of the Platform, and further subject to any as described in the applicable Exhibit or Order
                          or in any documentation or terms accompanying such additional software.
                        </li>
                        <li>
                          <u>Third Party Items.</u> During the Term of this Agreement, CPG Seller may receive or access items owned or provided by third parties (for example a
                          retailer’s trademarks or logos or copyright-protected text and images)(collectively “Third Party Items”). Such items are owned by the applicable third party, are
                          licensed and not sold, and CPG Seller shall use such Third Party Items in compliance with any and all third party terms and conditions (for example, a third party’s
                          description of how a trademark may be displayed). Pear Commerce is not responsible for and makes no representation or warranty regarding any Third Party Items.
                        </li>
                        <li>
                          <u>Pear Commerce Owns IP in Pear Platform; Feedback.</u> Pear Commerce and its licensors reserve all rights, including but not limited to all Intellectual
                          Property Rights, in and to the Platform including any updates to the Platform. “Intellectual Property Rights” means U.S. and non-U.S. patents, trademarks, copyrights,
                          trade secrets, software, databases, and any other similar items recognized as intellectual or proprietary rights in any jurisdiction.
                          <br></br>
                          <br></br>
                          In the event CPG Seller chooses to provide Pear Commerce with any feedback, suggestions, or similar communications, all such messages are referred to as,
                          collectively, "Feedback Messages." CPG Seller hereby grants and agrees to grant to Pear Commerce a perpetual, sublicensable, assignable, unrestricted, worldwide,
                          royalty-free, irrevocable license to use, reproduce, display, perform, practice, modify, create derivative or collective works, transmit, and distribute Feedback
                          Messages, in whole or in part, and including all Intellectual Property Rights therein.
                        </li>
                        <li>
                          <u>Platform Operation.</u> Pear Commerce shall, with the assistance of third parties (for example third party hosting entities), operate and make available the
                          Platform. Pear Commerce may modify, revise, or discontinue elements or features of the Platform at any time, with or without prior written notice. Pear Commerce
                          may work with, or decline to work with, different retailers, as desired by Pear Commerce in its business judgement. Pear Commerce may contract with third party
                          vendors to assist Pear Commerce in its performance under this Agreement (for example, Pear Commerce may engage a third party hosting service, and Pear
                          Commerce may purchase advertising from third party digital properties or other publishers).
                        </li>
                        <li>
                          <u>Mutual Trademark License.</u> Each Party hereby grants the other Party, only for the Term, a limited, non-exclusive, right and license to the trademarks and
                          trade names of such Party as reasonably necessary to perform under this Agreement, for Pear to display CPG Seller’s branded to operate the Platform, or to show in
                          product demonstrations to current and potential investors and customers. Each Party shall use reasonable efforts to follow the style and other guides regarding the
                          trademarks of the other Party.
                        </li>
                        <li>
                          <u>Term and Termination.</u> The Term shall be as set forth in the Order Form. Upon termination or expiration of this Agreement, the duties of the Parties shall
                          cease, except for the following sections which shall survive expiration and/or termination: 3, 4, 7, 8, 9, 10, and 11. Following termination, CPG Seller shall remain
                          liable to pay for all fees incurred prior to the termination date
                        </li>
                        <li>
                          <u>Confidentiality – Mutual.</u>  The term “Confidential Information” means the existence and terms of this Agreement including all Orders, as well as
                          information in any form disclosed or made available by a party hereunder (the “Discloser”) to the other party (the “Recipient”) that the Recipient knows or has
                          reason to know is confidential information of the Discloser shall include, without limitation: information, data, statistics, programs, methods or practices; response
                          rates or statistics, demographic information; vendor information; the current, future and proposed products of services of the Discloser as well as financial,
                          proprietary, technical, developmental research, operational, sales and marketing information related thereto.
                          <br></br>
                          <br></br>
                          Each party agrees that for a period of at least five (5) years, and for trade secret information for as long as such item remains a trade secret, it shall treat as
                          confidential all Confidential Information received from the other party, shall not use such Confidential Information except as expressly permitted under this
                          Agreement, and shall not disclose such Confidential Information to any third party without the other party’s prior written consent, except that a Party may disclose
                          Confidential Information to its contractors, consultants or vendors assisting such Party so long as such third parties are contractually required to preserve the
                          confidentiality thereof on terms at least as strict as those in this Agreement, and such Party is responsible for the acts and omission of such third parties as if
                          they were their own as to Confidential Information. Confidential Information may be shared with CPG Sellers and their agents to the extent reasonably necessary for the
                          provision of the Platform and the measuring of traffic from the Platform. Each party shall take reasonable measures, at least as great as the precautions it takes to
                          protect its own confidential information, to prevent the disclosure and unauthorized use of Confidential Information of the other party. Confidential Information may
                          be disclosed, reproduced, summarized or distributed only in pursuance of the applicable recipient’s business relationship with the party disclosing such Confidential
                          Information. Each party acknowledges that unauthorized disclosure or use of the other party’s Confidential Information will cause irreparable harm to such other
                          party. Each party agrees that money damages would not be sufficient remedy for any breach by it of this Agreement and that the non-breaching party shall be
                          entitled to specific performance and injunctive relief as a remedy for any such breach.
                          <br></br>
                          <br></br>
                          Notwithstanding the above, the restrictions of this Section shall not apply to information that: (a) was independently developed by the Recipient without any use of
                          the Confidential Information of the Discloser and by employees or other agents of (or independent contractors hired by) the Recipient who have not been exposed to
                          the Confidential Information; (b) becomes known to the Recipient, without restriction, from a third party without breach of an obligation of confidentiality and who
                          had a right to disclose it; (c) was in the public domain at the time it was disclosed or becomes in the public domain through no act or omission of the Recipient; (d)
                          was rightfully known to the Recipient, without restriction, at the time of disclosure; or (e) is disclosed pursuant to the order or requirement of a court, administrative
                          agency, or other governmental body; provided, however, that the Recipient shall provide notice as soon as is reasonably practicable to the Discloser and shall provide
                          reasonable assistance to the Discloser to obtain a protective order or otherwise prevent public disclosure of such Confidential Information and such Confidential
                          Information shall only lose its confidentiality protection for purposes of such legal disclosure.
                        </li>
                        <li>
                          <u>Warranties - Mutual.</u>
                          <ol type="a">
                            <li>
                              <u> Representations and Warranties of Pear Commerce.</u> Pear Commerce represents and warrants: (i) that it owns or has licensed all rights necessary to
                              provide the Platform and that the Platform does not infringe any US Intellectual Property Right of any third party; (ii) that it will comply with all applicable U.S. laws
                              and regulations related to its performance under this Agreement, and (iii) that it is authorized and has all authority necessary to enter into and perform under this
                              Agreement and that this Agreement constitutes a binding and enforceable agreement between the Parties. In the event that the Platform, or any portion thereof, is
                              alleged to be or is enjoined or is held to be an infringement, Pear Commerce shall have the option to, at Pear Commerce’s expense and as CPG Seller’s sole and
                              exclusive remedy, either (a) procure for CPG Seller the right to continue to use the Platform and/or Documentation; (b) modify the items in question so that it
                              becomes non-infringing, or (c) terminate this Agreement and return to CPG Seller any pre-paid fees.
                            </li>
                            <li>
                              <u>Representations and Warranties of CPG Seller.</u> CPG Seller represents and warrants: (i) that it owns or has licensed all rights necessary to
                              perform under this Agreement and that CPG Seller’s performance will not infringe any US Intellectual Property Right of any third party; (ii) that it will comply with all
                              applicable U.S. laws and regulations related to its performance under this Agreement including its collection and use of data provided by Consumers through the
                              retailer Site.
                            </li>
                            <li>
                              <u>No Other Warranties; Disclaimer.</u> THE AFFIRMATIVE REPRESENTATIONS AND WARRANTIES OF BOTH PARTIES THAT ARE EXPRESSLY SET FORTH IN THIS
                              AGREEMENT ARE THE ONLY REPRESENTATIONS AND WARRANTIES PROVIDED UNDER OR RELATED TO THIS AGREEMENT. NOTWITHSTANDING ANYTHING ELSE, TO
                              THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, SUBJECT ONLY TO THE EXPRESS WARRANTIES PROVIDED UNDER THE AGREEMENT, THE PLATFORM, THE
                              CPG SELLER TECHNOLOGY, ALL INFORMATION AND ITEMS PROVIDED BY RETAILERS, CONSUMER TRAFFIC AND INFORMATION AND DATA PROVIDED BY CONSUMERS,
                              AND CONFIDENTIAL INFORMATION, ARE PROVIDED ON AN “AS-IS, AS-AVAILABLE” BASIS. BOTH PARTIES EXPRESSLY DISCLAIM ANY AND ALL OTHER WARRANTIES,
                              EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION: (A) ANY AND ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE; (B)
                              ANY WARRANTY REGARDING RESULTS OBTAINABLE OR TO BE OBTAINED. BOTH PARTIES AS WELL AS RETAILERS RESERVE THE RIGHT TO CORRECT ANY
                              TYPOGRAPHICAL ERRORS IN OFFER PRICING OR TERMS, AND A PARTY SHALL NOT BE REQUIRED TO HONOR SUCH TYPOGRAPHICAL ERRORS APPEARING IN ANY
                              OFFER POSTED ON THE PLATFORM. NEITHER PARTY REPRESENTS NOR WARRANTS THAT THE OFFERS OR PARTICULAR CPG PRODUCTS ARE LEGAL, VALID OR
                              APPROPRIATE IN ALL JURISDICTIONS; OFFERS AND CPG PRODUCTS ARE VOID WHERE PROHIBITED. CPG SELLER UNDERSTANDS AND ACKNOWLEDGES THAT THIS IS A
                              NON-EXCLUSIVE AGREEMENT, AND THAT PEAR COMMERCE WILL SEND CONSUMER TRAFFIC TO MULTIPLE ENTITIES, INCLUDING BUT NOT LIMITED TO ENTITIES THAT
                              MAY BE COMPETITORS OF CPG SELLER. PEAR COMMERCE IS AN ENTITY BASED IN THE U.S. AND INTENDS TO PROMOTE OFFERS TO CONSUMERS AND RETAILERS IN
                              THE U.S. ONLY. ALTHOUGH THE PLATFORM AND/OR OFFERS MAY BE VIEWED OUTSIDE OF THE U.S., SUCH OUTSIDE U.S. INDIVIDUALS MAY BE BLOCKED. PEAR
                              COMMERCE DOES NOT AGREE TO BE SUBJECT TO ANY LAWS OUTSIDE OF THE U.S.
                            </li>
                          </ol>
                        </li>
                        <br></br>
                        <li>
                          <u>MUTUAL Limitation of Remedies.</u> IN NO EVENT WILL EITHER PARTY’S LIABILITY UNDER OR RELATED TO THIS AGREEMENT, FOR REGARDLESS OF THE
                          FORM OF ACTION, INCLUDE ANY INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES OR CLAIMS FOR LOSS OF BUSINESS OR PROFITS, UNDER CONTRACT,
                          TORT (INCLUDING NEGLIGENCE), OR OTHER LEGAL THEORY, REGARDLESS OF THE CAUSE OF ACTION AND EVEN IF SUCH PARTY HAS BEEN ADVISED OF THE
                          POSSIBILITY OF SUCH POTENTIAL LOSS OR DAMAGE. EACH PARTY’S MAXIMUM AGGREGATE LIABILITY TO THE OTHER PARTY, OR ANY ENTITY WITH RIGHTS THROUGH
                          THE OTHER PARTY, UNDER OR RELATING TO THIS AGREEMENT UNDER CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, REGARDLESS OF
                          THE CAUSE OF ACTION WILL NOT EXCEED AN AMOUNT, IN AGGREGATE, EQUAL TO THE FEES PAID (OR OWING BUT UNPAID) BY CPG SELLER TO PEAR IN THE THREE
                          MONTHS PRIOR TO WHEN THE DAMAGES FIRST AROSE. THE LIMITS IN THIS SECTION SHALL NOT APPLY TO BREACH OF A PARTY’S INTELLECTUAL PROPERTY RIGHTS
                          OR TO BREACH OF CONFIDENTIALITY OBLIGATIONS.
                        </li>
                        <li>
                          <u>General.</u>
                          <ol type="a">
                            <li>
                              <u>Waiver and Amendment.</u> No modification, amendment or waiver of any provision of this Agreement shall be effective unless in writing and
                              signed by the parties hereto. Order confirmation terms or similar documents or terms are rejected. The failure of either Party to seek relief for the other Party’s
                              breach of any duty under this Agreement shall not waive any right of the non-breaching party to seek relief for any subsequent breach.
                            </li>
                            <li>
                              <u>Relationship.</u> The Parties and their respective personnel, are and shall be independent contractors, and neither party by virtue of this
                              Agreement shall have any right, power or authority to act or create any obligation, express or implied, on behalf of the other party.
                            </li>
                            <li>
                              <u>Assignment.</u> The Parties shall not assign or transfer this Agreement without the express prior written consent of the other, which consent
                              shall not be unreasonably withheld or delayed. Notwithstanding the foregoing, either Party may assign its rights and obligations hereunder to a successor in the
                              event of a change of control transaction such as a merger, reorganization, acquisition, sale of all or substantially all of its assets or similar transaction
                            </li>
                            <li>
                              <u>Force Majeure.</u> Neither party shall be deemed in default of the Agreement to the extent that performance of its obligations or attempts to
                              cure any breach are delayed, restricted or prevented by reason of any act of God, fire, natural disaster, act of government, strikes or labor disputes, inability to
                              provide raw materials, power or supplies, or any other similar act or condition beyond the reasonable control of the parties, provided that the party so affected uses
                              commercially reasonable efforts to avoid and remove the causes of nonperformance and continues performance hereunder immediately after those causes are
                              removed.
                            </li>
                            <li>
                              <u>Counterparts</u> This Agreement may be executed in several counterparts, all of which taken together shall constitute the entire Agreement
                              between the parties. This Agreement may be executed and delivered with facsimile signatures with the same force and effect as original signatures.
                            </li>
                            <li>
                              <u>Governing Law.</u> The construction, validity and performance of this Agreement shall be governed by, and construed in accordance with, the
                              laws of the State of Minnesota, without regard to principles thereof relating to conflict of laws. Any dispute arising under or related to this Agreement shall be
                              venued in and subject to the jurisdiction of the state and federal courts located in Minnesota and each party hereby submits to the jurisdiction and proper venue of
                              such courts.
                            </li>
                            <li>
                              <u>Entire Agreement.</u> Each party to this Agreement acknowledges that this Agreement constitutes the entire Agreement of the parties with
                              regard to the subject matter addressed in this Agreement; supersedes all prior or contemporaneous agreements, discussions, or representations, whether oral or
                              written, with respect to the subject matter of this Agreement; and cannot be amended except by a writing singed by all parties hereto.
                            </li>
                          </ol>
                        </li>
                      </ol>
                      <br></br>
                      <br></br>
                      END OF TERMS AND CONDITION
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default TermsConditions;
